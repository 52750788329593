import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";

import logo from "../images/bayan-logo.png";

const detailsQuery = graphql`
  query {
    basicJson {
      title
      globalKeywords
      social {
        href
      }
    }
  }
`;

function SEO({ description, lang, meta, keywords, title }) {
  const data = useStaticQuery(detailsQuery);
  const {
    basicJson: { globalKeywords, social },
  } = data;
  const profiles = social.map((item) => item.href);
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1.0",
        },
        {
          name: "description",
          content: description,
        },
        {
          property: "og:title",
          content: title,
        },
        {
          property: "og:description",
          content: description,
        },
        {
          property: `og:type`,
          content: "website",
        },
        {
          name: `twitter:card`,
          content: "summary",
        },
        {
          name: `twitter:creator`,
          content: "Bayan Bennett",
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          name: "keywords",
          content: [...globalKeywords, ...(keywords || [])].join(", "),
        },
      ].concat(meta)}
    >
      <script type="application/ld+json">
        {`{
                "@context": "https://schema.org",
                "@type": "Person",
                "name": "Bayan Bennett",
                "logo": "${logo}",
                "url": "https://www.bayanbennett.com",
                "sameAs": ${JSON.stringify(profiles)}
              }`}
      </script>
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default SEO;
