import React from "react";
import PropTypes from "prop-types";

import SEO from "./seo";

const Layout = ({ children, title, keywords, description }) => (
  <>
    <SEO title={title} keywords={keywords} description={description} />
    {children}
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
